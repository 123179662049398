import { getState, signalStoreFeature, withMethods, withHooks, watchState, patchState as patchState$1, withState, withComputed, withProps } from '@ngrx/signals';
import * as i0 from '@angular/core';
import { inject, PLATFORM_ID, Injectable, signal, effect, InjectionToken, computed, isSignal, untracked, isDevMode as isDevMode$1 } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Subject } from 'rxjs';
import { setAllEntities, addEntity, updateEntity, removeEntity } from '@ngrx/signals/entities';

/**
 * Stub for DevTools integration. Can be used to disable DevTools in production.
 */
const withDevToolsStub = () => store => store;
const currentActionNames = new Set();
function throwIfNull(obj) {
  if (obj === null || obj === undefined) {
    throw new Error('');
  }
  return obj;
}
const dummyConnection = {
  send: () => void true
};
/**
 * A service provided by the root injector is
 * required because the synchronization runs
 * globally.
 *
 * The SignalStore could be provided in a component.
 * If the effect starts in the injection
 * context of the SignalStore, the complete sync
 * process would shut down once the component gets
 * destroyed.
 */
class DevtoolsSyncer {
  /**
   * Stores all SignalStores that are connected to the
   * DevTools along their options, names and id.
   */
  #stores = {};
  #isBrowser = isPlatformBrowser(inject(PLATFORM_ID));
  #trackers = [];
  /**
   * Maintains the current states of all stores to avoid conflicts
   * between glitch-free and glitched trackers when used simultaneously.
   *
   * The challenge lies in ensuring that glitched trackers do not
   * interfere with the synchronization process of glitch-free trackers.
   * Specifically, glitched trackers could cause the synchronization to
   * read the current state of stores managed by glitch-free trackers.
   *
   * Therefore, the synchronization process doesn't read the state from
   * each store, but relies on #currentState.
   *
   * Please note, that here the key is the name and not the id.
   */
  #currentState = {};
  #currentId = 1;
  #connection = this.#isBrowser ? window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__.connect({
    name: 'NgRx SignalStore'
  }) : dummyConnection : dummyConnection;
  constructor() {
    if (!this.#isBrowser) {
      return;
    }
    const isToolkitAvailable = Boolean(window.__REDUX_DEVTOOLS_EXTENSION__);
    if (!isToolkitAvailable) {
      console.info('NgRx Toolkit/DevTools: Redux DevTools Extension is not available.');
    }
  }
  ngOnDestroy() {
    currentActionNames.clear();
  }
  syncToDevTools(changedStatePerId) {
    const mappedChangedStatePerName = Object.entries(changedStatePerId).reduce((acc, [id, store]) => {
      const {
        options,
        name
      } = this.#stores[id];
      acc[name] = options.map(store);
      return acc;
    }, {});
    this.#currentState = {
      ...this.#currentState,
      ...mappedChangedStatePerName
    };
    const names = Array.from(currentActionNames);
    const type = names.length ? names.join(', ') : 'Store Update';
    currentActionNames.clear();
    this.#connection.send({
      type
    }, this.#currentState);
  }
  getNextId() {
    return String(this.#currentId++);
  }
  /**
   * Consumer provides the id. That is because we can only start
   * tracking the store in the init hook.
   * Unfortunately, methods for renaming having the final id
   * need to be defined already before.
   * That's why `withDevtools` requests first the id and
   * then registers itself later.
   */
  addStore(id, name, store, options) {
    let storeName = name;
    const names = Object.values(this.#stores).map(store => store.name);
    if (names.includes(storeName)) {
      // const { options } = throwIfNull(
      //   Object.values(this.#stores).find((store) => store.name === storeName)
      // );
      if (!options.indexNames) {
        throw new Error(`An instance of the store ${storeName} already exists. \
Enable automatic indexing via withDevTools('${storeName}', { indexNames: true }), or rename it upon instantiation.`);
      }
    }
    for (let i = 1; names.includes(storeName); i++) {
      storeName = `${name}-${i}`;
    }
    this.#stores[id] = {
      name: storeName,
      options
    };
    const tracker = options.tracker;
    if (!this.#trackers.includes(tracker)) {
      this.#trackers.push(tracker);
    }
    tracker.onChange(changedState => this.syncToDevTools(changedState));
    tracker.track(id, store);
  }
  removeStore(id) {
    const name = this.#stores[id].name;
    this.#stores = Object.entries(this.#stores).reduce((newStore, [storeId, value]) => {
      if (storeId !== id) {
        newStore[storeId] = value;
      }
      return newStore;
    }, {});
    this.#currentState = Object.entries(this.#currentState).reduce((newState, [storeName, state]) => {
      if (storeName !== name) {
        newState[name] = state;
      }
      return newState;
    }, {});
    for (const tracker of this.#trackers) {
      tracker.removeStore(id);
    }
  }
  renameStore(oldName, newName) {
    const storeNames = Object.values(this.#stores).map(store => store.name);
    const id = throwIfNull(Object.keys(this.#stores).find(id => this.#stores[id].name === oldName));
    if (storeNames.includes(newName)) {
      throw new Error(`NgRx Toolkit/DevTools: cannot rename from ${oldName} to ${newName}. ${newName} is already assigned to another SignalStore instance.`);
    }
    this.#stores = Object.entries(this.#stores).reduce((newStore, [id, value]) => {
      if (value.name === oldName) {
        newStore[id] = {
          ...value,
          name: newName
        };
      } else {
        newStore[id] = value;
      }
      return newStore;
    }, {});
    // we don't rename in #currentState but wait for tracker to notify
    // us with a changed state that contains that name.
    this.#currentState = Object.entries(this.#currentState).reduce((newState, [storeName, state]) => {
      if (storeName !== oldName) {
        newState[storeName] = state;
      }
      return newState;
    }, {});
    this.#trackers.forEach(tracker => tracker.notifyRenamedStore(id));
  }
  static {
    this.ɵfac = function DevtoolsSyncer_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DevtoolsSyncer)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: DevtoolsSyncer,
      factory: DevtoolsSyncer.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DevtoolsSyncer, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [], null);
})();
class DefaultTracker {
  #stores = signal({});
  get stores() {
    return this.#stores();
  }
  #trackCallback;
  #trackingEffect = effect(() => {
    if (this.#trackCallback === undefined) {
      throw new Error('no callback function defined');
    }
    const stores = this.#stores();
    const fullState = Object.entries(stores).reduce((acc, [id, store]) => {
      return {
        ...acc,
        [id]: getState(store)
      };
    }, {});
    this.#trackCallback(fullState);
  });
  track(id, store) {
    this.#stores.update(value => ({
      ...value,
      [id]: store
    }));
  }
  onChange(callback) {
    this.#trackCallback = callback;
  }
  removeStore(id) {
    this.#stores.update(stores => Object.entries(stores).reduce((newStore, [storeId, state]) => {
      if (storeId !== id) {
        newStore[storeId] = state;
      }
      return newStore;
    }, {}));
  }
  notifyRenamedStore(id) {
    if (this.#stores()[id]) {
      this.#stores.update(stores => {
        return {
          ...stores
        };
      });
    }
  }
  static {
    this.ɵfac = function DefaultTracker_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DefaultTracker)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: DefaultTracker,
      factory: DefaultTracker.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DefaultTracker, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
const renameDevtoolsMethodName = '___renameDevtoolsName';
const uniqueDevtoolsId = '___uniqueDevtoolsId';
const EXISTING_NAMES = new InjectionToken('Array contain existing names for the signal stores', {
  factory: () => [],
  providedIn: 'root'
});
/**
 * Adds this store as a feature state to the Redux DevTools.
 *
 * By default, the action name is 'Store Update'. You can
 * change that via the {@link updateState} method, which has as second
 * parameter the action name.
 *
 * The standalone function {@link renameDevtoolsName} can rename
 * the store name.
 *
 * @param name name of the store as it should appear in the DevTools
 * @param features features to extend or modify the behavior of the Devtools
 */
function withDevtools(name, ...features) {
  return signalStoreFeature(withMethods(() => {
    const syncer = inject(DevtoolsSyncer);
    const id = syncer.getNextId();
    // TODO: use withProps and symbols
    return {
      [renameDevtoolsMethodName]: newName => {
        syncer.renameStore(name, newName);
      },
      [uniqueDevtoolsId]: () => id
    };
  }), withHooks(store => {
    const syncer = inject(DevtoolsSyncer);
    const id = String(store[uniqueDevtoolsId]());
    return {
      onInit() {
        const id = String(store[uniqueDevtoolsId]());
        const finalOptions = {
          indexNames: !features.some(f => f.indexNames === false),
          map: features.find(f => f.map)?.map ?? (state => state),
          tracker: inject(features.find(f => f.tracker)?.tracker || DefaultTracker)
        };
        syncer.addStore(id, name, store, finalOptions);
      },
      onDestroy() {
        syncer.removeStore(id);
      }
    };
  }));
}
const DEVTOOLS_FEATURE = Symbol('DEVTOOLS_FEATURE');
function createDevtoolsFeature(options) {
  return {
    [DEVTOOLS_FEATURE]: true,
    ...options
  };
}

/**
 * If multiple instances of the same SignalStore class
 * exist, their devtool names are indexed.
 *
 * For example:
 *
 * ```typescript
 * const Store = signalStore(
 *   withDevtools('flights')
 * )
 *
 * const store1 = new Store(); // will show up as 'flights'
 * const store2 = new Store(); // will show up as 'flights-1'
 * ```
 *
 * With adding `withDisabledNameIndices` to the store:
 * ```typescript
 * const Store = signalStore(
 *   withDevtools('flights', withDisabledNameIndices())
 * )
 *
 * const store1 = new Store(); // will show up as 'flights'
 * const store2 = new Store(); //💥 throws an error
 * ```
 *
 */
function withDisabledNameIndices() {
  return createDevtoolsFeature({
    indexNames: false
  });
}

/**
 * Allows you to define a function to map the state.
 *
 * It is needed for huge states, that slows down the Devtools and where
 * you don't need to see the whole state or other reasons.
 *
 * Example:
 *
 * ```typescript
 * const initialState = {
 *   id: 1,
 *   email: 'john.list@host.com',
 *   name: 'John List',
 *   enteredPassword: ''
 * }
 *
 * const Store = signalStore(
 *   withState(initialState),
 *   withDevtools(
 *     'user',
 *     withMapper(state => ({...state, enteredPassword: '***' }))
 *   )
 * )
 * ```
 *
 * @param map function which maps the state
 */
function withMapper(map) {
  return createDevtoolsFeature({
    map: map
  });
}

/**
 * Internal Service used by {@link withGlitchTracking}. It does not rely
 * on `effect` as {@link DefaultTracker} does but uses the NgRx function
 * `watchState` to track all state changes.
 */
class GlitchTrackerService {
  #stores = {};
  #callback;
  get stores() {
    return Object.entries(this.#stores).reduce((acc, [id, {
      store
    }]) => {
      acc[id] = store;
      return acc;
    }, {});
  }
  onChange(callback) {
    this.#callback = callback;
  }
  removeStore(id) {
    this.#stores = Object.entries(this.#stores).reduce((newStore, [storeId, value]) => {
      if (storeId !== id) {
        newStore[storeId] = value;
      } else {
        value.destroyWatcher();
      }
      return newStore;
    }, {});
    throwIfNull(this.#callback)({});
  }
  track(id, store) {
    const watcher = watchState(store, state => {
      throwIfNull(this.#callback)({
        [id]: state
      });
    });
    this.#stores[id] = {
      destroyWatcher: watcher.destroy,
      store
    };
  }
  notifyRenamedStore(id) {
    if (Object.keys(this.#stores).includes(id) && this.#callback) {
      this.#callback({
        [id]: getState(this.#stores[id].store)
      });
    }
  }
  static {
    this.ɵfac = function GlitchTrackerService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || GlitchTrackerService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: GlitchTrackerService,
      factory: GlitchTrackerService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GlitchTrackerService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();

/**
 * It tracks all state changes of the State, including intermediary updates
 * that are typically suppressed by Angular's glitch-free mechanism.
 *
 * This feature is especially useful for debugging.
 *
 * Example:
 *
 * ```typescript
 * const Store = signalStore(
 *   { providedIn: 'root' },
 *   withState({ count: 0 }),
 *   withDevtools('counter', withGlitchTracking()),
 *   withMethods((store) => ({
 *     increase: () =>
 *       patchState(store, (value) => ({ count: value.count + 1 })),
 *   }))
 * );
 *
 * // would show up in the DevTools with value 0
 * const store = inject(Store);
 *
 * store.increase(); // would show up in the DevTools with value 1
 * store.increase(); // would show up in the DevTools with value 2
 * store.increase(); // would show up in the DevTools with value 3
 * ```
 *
 * Without `withGlitchTracking`, the DevTools would only show the final value of 3.
 */
function withGlitchTracking() {
  return createDevtoolsFeature({
    tracker: GlitchTrackerService
  });
}

/**
 * @deprecated Has been renamed to `updateState`
 */
const patchState = (state, action, ...rest) => {
  updateState(state, action, ...rest);
};
/**
 * Wrapper of `patchState` for DevTools integration. Next to updating the state,
 * it also sends the action to the DevTools.
 * @param stateSource state of Signal Store
 * @param action name of action how it will show in DevTools
 * @param updaters updater functions or objects
 */
function updateState(stateSource, action, ...updaters) {
  currentActionNames.add(action);
  return patchState$1(stateSource, ...updaters);
}

/**
 * Renames the name of a store how it appears in the Devtools.
 * @param store instance of the SignalStore
 * @param newName new name for the Devtools
 */
function renameDevtoolsName(store, newName) {
  const renameMethod = store[renameDevtoolsMethodName];
  if (!renameMethod) {
    throw new Error("Devtools extensions haven't been added to this store.");
  }
  renameMethod(newName);
}
function assertActionFnSpecs(obj) {
  if (!obj || typeof obj !== 'object') {
    throw new Error('%o is not an Action Specification');
  }
}
function payload() {
  return {};
}
const noPayload = {};
/**
 * Creates a reducer function to separate the reducer logic into another file.
 *
 * ```typescript
 * interface FlightState {
 *   flights: Flight[];
 *   effect1: boolean;
 *   effect2: boolean;
 * }
 *
 * const initialState: FlightState = {
 *   flights: [],
 *   effect1: false,
 *   effect2: false,
 * };
 *
 * const actions = {
 *   init: noPayload,
 *   updateEffect1: payload<{ value: boolean }>(),
 *   updateEffect2: payload<{ value: boolean }>(),
 * };
 *
 * const reducer = createReducer<FlightState, typeof actions>((actions, on) => {
 *   on(actions.updateEffect1, (state, { value }) => {
 *     patchState(state, { effect1: value });
 *   });
 *
 *   on(actions.updateEffect2, (state, { value }) => {
 *     patchState(state, { effect2: value });
 *   });
 * });
 *
 * signalStore(
 *   withState(initialState),
 *   withRedux({
 *     actions,
 *     reducer,
 *   })
 * );
 * ```
 * @param reducerFactory
 */
function createReducer(reducerFactory) {
  return reducerFactory;
}
/**
 * Creates the effects function to separate the effects logic into another file.
 *
 * ```typescript
 * interface FlightState {
 *   flights: Flight[];
 *   effect1: boolean;
 *   effect2: boolean;
 * }
 *
 * const initialState: FlightState = {
 *   flights: [],
 *   effect1: false,
 *   effect2: false,
 * };
 *
 * const actions = {
 *   init: noPayload,
 *   updateEffect1: payload<{ value: boolean }>(),
 *   updateEffect2: payload<{ value: boolean }>(),
 * };
 *
 * const effects = createEffects(actions, (actions, create) => {
 *   return {
 *     init1$: create(actions.init).pipe(
 *       map(() => actions.updateEffect1({ value: true }))
 *     ),
 *     init2$: create(actions.init).pipe(
 *       map(() => actions.updateEffect2({ value: true }))
 *     ),
 *   };
 * });
 *
 * signalStore(
 *   withState(initialState),
 *   withRedux({
 *     actions,
 *     effects,
 *   })
 * );
 * ```
 * @param actions
 * @param effectsFactory
 */
function createEffects(actions, effectsFactory) {
  return effectsFactory;
}
function createActionFns(actionFnSpecs, reducerRegistry, effectsRegistry, state) {
  const actionFns = {};
  for (const type in actionFnSpecs) {
    const actionFn = payload => {
      const fullPayload = {
        ...payload,
        type
      };
      const reducer = reducerRegistry[type];
      if (reducer) {
        reducer(state, fullPayload);
      }
      const effectSubjects = effectsRegistry[type];
      if (effectSubjects?.length) {
        for (const effectSubject of effectSubjects) {
          effectSubject.next(fullPayload);
        }
      }
      return fullPayload;
    };
    actionFn.type = type.toString();
    actionFns[type] = actionFn;
  }
  return actionFns;
}
function createPublicAndAllActionsFns(actionFnSpecs, reducerRegistry, effectsRegistry, state) {
  if ('public' in actionFnSpecs || 'private' in actionFnSpecs) {
    const privates = actionFnSpecs['private'] || {};
    const publics = actionFnSpecs['public'] || {};
    assertActionFnSpecs(privates);
    assertActionFnSpecs(publics);
    const privateActionFns = createActionFns(privates, reducerRegistry, effectsRegistry, state);
    const publicActionFns = createActionFns(publics, reducerRegistry, effectsRegistry, state);
    return {
      all: {
        ...privateActionFns,
        ...publicActionFns
      },
      publics: publicActionFns
    };
  }
  const actionFns = createActionFns(actionFnSpecs, reducerRegistry, effectsRegistry, state);
  return {
    all: actionFns,
    publics: actionFns
  };
}
function fillReducerRegistry(reducer, actionFns, reducerRegistry) {
  function on(action, reducerFn) {
    reducerRegistry[action.type] = reducerFn;
  }
  reducer(actionFns, on);
  return reducerRegistry;
}
function fillEffects(effects, actionFns, effectsRegistry = {}) {
  function create(action) {
    const subject = new Subject();
    if (!(action.type in effectsRegistry)) {
      effectsRegistry[action.type] = [];
    }
    effectsRegistry[action.type].push(subject);
    return subject.asObservable();
  }
  const effectObservables = effects(actionFns, create);
  return Object.values(effectObservables);
}
function startSubscriptions(observables) {
  return observables.map(observable => observable.subscribe());
}
function processRedux(actionFnSpecs, reducer, effects, store) {
  const reducerRegistry = {};
  const effectsRegistry = {};
  const actionsMap = createPublicAndAllActionsFns(actionFnSpecs, reducerRegistry, effectsRegistry, store);
  const actionFns = actionsMap.all;
  const publicActionsFns = actionsMap.publics;
  fillReducerRegistry(reducer, actionFns, reducerRegistry);
  const effectObservables = fillEffects(effects, actionFns, effectsRegistry);
  const subscriptions = startSubscriptions(effectObservables);
  return {
    methods: publicActionsFns,
    subscriptions: subscriptions
  };
}
/**
 * @param redux redux
 *
 * properties do not start with `with` since they are not extension functions on their own.
 *
 * no dependency to NgRx
 *
 * actions are passed to reducer and effects, but it is also possible to use other actions.
 * effects provide forAction and do not return anything. that is important because effects should stay inaccessible
 */
function withRedux(redux) {
  return store => {
    const {
      methods
    } = processRedux(redux.actions, redux.reducer, redux.effects, store);
    return {
      ...store,
      methods: {
        ...store.methods,
        ...methods
      }
    };
  };
}
function getCallStateKeys(config) {
  const prop = config?.collection;
  return {
    callStateKey: prop ? `${config.collection}CallState` : 'callState',
    loadingKey: prop ? `${config.collection}Loading` : 'loading',
    loadedKey: prop ? `${config.collection}Loaded` : 'loaded',
    errorKey: prop ? `${config.collection}Error` : 'error'
  };
}
function withCallState(config) {
  const {
    callStateKey,
    errorKey,
    loadedKey,
    loadingKey
  } = getCallStateKeys(config);
  return signalStoreFeature(withState({
    [callStateKey]: 'init'
  }), withComputed(state => {
    const callState = state[callStateKey];
    return {
      [loadingKey]: computed(() => callState() === 'loading'),
      [loadedKey]: computed(() => callState() === 'loaded'),
      [errorKey]: computed(() => {
        const v = callState();
        return typeof v === 'object' ? v.error : null;
      })
    };
  }));
}
function setLoading(prop) {
  if (prop) {
    return {
      [`${prop}CallState`]: 'loading'
    };
  }
  return {
    callState: 'loading'
  };
}
function setLoaded(prop) {
  if (prop) {
    return {
      [`${prop}CallState`]: 'loaded'
    };
  } else {
    return {
      callState: 'loaded'
    };
  }
}
function setError(error, prop) {
  let errorMessage;
  if (!error) {
    errorMessage = '';
  } else if (typeof error === 'object' && 'message' in error) {
    errorMessage = String(error.message);
  } else {
    errorMessage = String(error);
  }
  if (prop) {
    return {
      [`${prop}CallState`]: {
        error: errorMessage
      }
    };
  } else {
    return {
      callState: {
        error: errorMessage
      }
    };
  }
}
function capitalize(str) {
  return str ? str[0].toUpperCase() + str.substring(1) : str;
}
function getDataServiceKeys(options) {
  const filterKey = options.collection ? `${options.collection}Filter` : 'filter';
  const selectedIdsKey = options.collection ? `selected${capitalize(options.collection)}Ids` : 'selectedIds';
  const selectedEntitiesKey = options.collection ? `selected${capitalize(options.collection)}Entities` : 'selectedEntities';
  const updateFilterKey = options.collection ? `update${capitalize(options.collection)}Filter` : 'updateFilter';
  const updateSelectedKey = options.collection ? `updateSelected${capitalize(options.collection)}Entities` : 'updateSelected';
  const loadKey = options.collection ? `load${capitalize(options.collection)}Entities` : 'load';
  const currentKey = options.collection ? `current${capitalize(options.collection)}` : 'current';
  const loadByIdKey = options.collection ? `load${capitalize(options.collection)}ById` : 'loadById';
  const setCurrentKey = options.collection ? `setCurrent${capitalize(options.collection)}` : 'setCurrent';
  const createKey = options.collection ? `create${capitalize(options.collection)}` : 'create';
  const updateKey = options.collection ? `update${capitalize(options.collection)}` : 'update';
  const updateAllKey = options.collection ? `updateAll${capitalize(options.collection)}` : 'updateAll';
  const deleteKey = options.collection ? `delete${capitalize(options.collection)}` : 'delete';
  // TODO: Take these from @ngrx/signals/entities, when they are exported
  const entitiesKey = options.collection ? `${options.collection}Entities` : 'entities';
  const entityMapKey = options.collection ? `${options.collection}EntityMap` : 'entityMap';
  const idsKey = options.collection ? `${options.collection}Ids` : 'ids';
  return {
    filterKey,
    selectedIdsKey,
    selectedEntitiesKey,
    updateFilterKey,
    updateSelectedKey,
    loadKey,
    entitiesKey,
    entityMapKey,
    idsKey,
    currentKey,
    loadByIdKey,
    setCurrentKey,
    createKey,
    updateKey,
    updateAllKey,
    deleteKey
  };
}
function withDataService(options) {
  const {
    dataServiceType,
    filter,
    collection: prefix
  } = options;
  const {
    entitiesKey,
    filterKey,
    loadKey,
    selectedEntitiesKey,
    selectedIdsKey,
    updateFilterKey,
    updateSelectedKey,
    currentKey,
    createKey,
    updateKey,
    updateAllKey,
    deleteKey,
    loadByIdKey,
    setCurrentKey
  } = getDataServiceKeys(options);
  const {
    callStateKey
  } = getCallStateKeys({
    collection: prefix
  });
  return signalStoreFeature(withState(() => ({
    [filterKey]: filter,
    [selectedIdsKey]: {},
    [currentKey]: undefined
  })), withComputed(store => {
    const entities = store[entitiesKey];
    const selectedIds = store[selectedIdsKey];
    return {
      [selectedEntitiesKey]: computed(() => entities().filter(e => selectedIds()[e.id]))
    };
  }), withMethods(store => {
    const dataService = inject(dataServiceType);
    return {
      [updateFilterKey]: filter => {
        patchState$1(store, {
          [filterKey]: filter
        });
      },
      [updateSelectedKey]: (id, selected) => {
        patchState$1(store, state => ({
          [selectedIdsKey]: {
            ...state[selectedIdsKey],
            [id]: selected
          }
        }));
      },
      [loadKey]: async () => {
        const filter = store[filterKey];
        (() => store[callStateKey] && patchState$1(store, setLoading(prefix)))();
        try {
          const result = await dataService.load(filter());
          patchState$1(store, prefix ? setAllEntities(result, {
            collection: prefix
          }) : setAllEntities(result));
          (() => store[callStateKey] && patchState$1(store, setLoaded(prefix)))();
        } catch (e) {
          (() => store[callStateKey] && patchState$1(store, setError(e, prefix)))();
          throw e;
        }
      },
      [loadByIdKey]: async id => {
        (() => store[callStateKey] && patchState$1(store, setLoading(prefix)))();
        try {
          const current = await dataService.loadById(id);
          (() => store[callStateKey] && patchState$1(store, setLoaded(prefix)))();
          patchState$1(store, {
            [currentKey]: current
          });
        } catch (e) {
          (() => store[callStateKey] && patchState$1(store, setError(e, prefix)))();
          throw e;
        }
      },
      [setCurrentKey]: current => {
        patchState$1(store, {
          [currentKey]: current
        });
      },
      [createKey]: async entity => {
        patchState$1(store, {
          [currentKey]: entity
        });
        (() => store[callStateKey] && patchState$1(store, setLoading(prefix)))();
        try {
          const created = await dataService.create(entity);
          patchState$1(store, {
            [currentKey]: created
          });
          patchState$1(store, prefix ? addEntity(created, {
            collection: prefix
          }) : addEntity(created));
          (() => store[callStateKey] && patchState$1(store, setLoaded(prefix)))();
        } catch (e) {
          (() => store[callStateKey] && patchState$1(store, setError(e, prefix)))();
          throw e;
        }
      },
      [updateKey]: async entity => {
        patchState$1(store, {
          [currentKey]: entity
        });
        (() => store[callStateKey] && patchState$1(store, setLoading(prefix)))();
        try {
          const updated = await dataService.update(entity);
          patchState$1(store, {
            [currentKey]: updated
          });
          const updateArg = {
            id: updated.id,
            changes: updated
          };
          const updater = collection => updateEntity(updateArg, {
            collection
          });
          patchState$1(store, prefix ? updater(prefix) : updateEntity(updateArg));
          (() => store[callStateKey] && patchState$1(store, setLoaded(prefix)))();
        } catch (e) {
          (() => store[callStateKey] && patchState$1(store, setError(e, prefix)))();
          throw e;
        }
      },
      [updateAllKey]: async entities => {
        (() => store[callStateKey] && patchState$1(store, setLoading(prefix)))();
        try {
          const result = await dataService.updateAll(entities);
          patchState$1(store, prefix ? setAllEntities(result, {
            collection: prefix
          }) : setAllEntities(result));
          (() => store[callStateKey] && patchState$1(store, setLoaded(prefix)))();
        } catch (e) {
          (() => store[callStateKey] && patchState$1(store, setError(e, prefix)))();
          throw e;
        }
      },
      [deleteKey]: async entity => {
        patchState$1(store, {
          [currentKey]: entity
        });
        (() => store[callStateKey] && patchState$1(store, setLoading(prefix)))();
        try {
          await dataService.delete(entity);
          patchState$1(store, {
            [currentKey]: undefined
          });
          patchState$1(store, prefix ? removeEntity(entity.id, {
            collection: prefix
          }) : removeEntity(entity.id));
          (() => store[callStateKey] && patchState$1(store, setLoaded(prefix)))();
        } catch (e) {
          (() => store[callStateKey] && patchState$1(store, setError(e, prefix)))();
          throw e;
        }
      }
    };
  }));
}
const defaultOptions = {
  maxStackSize: 100,
  keys: [],
  skip: 0
};
function getUndoRedoKeys(collections) {
  if (collections) {
    return collections.flatMap(c => [`${c}EntityMap`, `${c}Ids`, `selected${capitalize(c)}Ids`, `${c}Filter`]);
  }
  return ['entityMap', 'ids', 'selectedIds', 'filter'];
}
function withUndoRedo(options) {
  let previous = null;
  let skipOnce = false;
  const normalized = {
    ...defaultOptions,
    ...options
  };
  //
  // Design Decision: This feature has its own
  // internal state.
  //
  const undoStack = [];
  const redoStack = [];
  const canUndo = signal(false);
  const canRedo = signal(false);
  const updateInternal = () => {
    canUndo.set(undoStack.length !== 0);
    canRedo.set(redoStack.length !== 0);
  };
  const keys = [...getUndoRedoKeys(normalized.collections), ...normalized.keys];
  return signalStoreFeature(withComputed(() => ({
    canUndo: canUndo.asReadonly(),
    canRedo: canRedo.asReadonly()
  })), withMethods(store => ({
    undo() {
      const item = undoStack.pop();
      if (item && previous) {
        redoStack.push(previous);
      }
      if (item) {
        skipOnce = true;
        patchState$1(store, item);
        previous = item;
      }
      updateInternal();
    },
    redo() {
      const item = redoStack.pop();
      if (item && previous) {
        undoStack.push(previous);
      }
      if (item) {
        skipOnce = true;
        patchState$1(store, item);
        previous = item;
      }
      updateInternal();
    },
    clearStack() {
      undoStack.splice(0);
      redoStack.splice(0);
      updateInternal();
    }
  })), withHooks({
    onInit(store) {
      effect(() => {
        const cand = keys.reduce((acc, key) => {
          const s = store[key];
          if (s && isSignal(s)) {
            return {
              ...acc,
              [key]: s()
            };
          }
          return acc;
        }, {});
        if (normalized.skip > 0) {
          normalized.skip--;
          return;
        }
        if (skipOnce) {
          skipOnce = false;
          return;
        }
        //
        // Deep Comparison to prevent duplicated entries
        // on the stack. This can e.g. happen after an undo
        // if the component sends back the undone filter
        // to the store.
        //
        if (JSON.stringify(cand) === JSON.stringify(previous)) {
          return;
        }
        // Clear redoStack after recorded action
        redoStack.splice(0);
        if (previous) {
          undoStack.push(previous);
        }
        if (redoStack.length > normalized.maxStackSize) {
          undoStack.unshift();
        }
        previous = cand;
        // Don't propogate current reactive context
        untracked(() => updateInternal());
      });
    }
  }));
}
const NOOP = () => void true;
const StorageSyncStub = {
  clearStorage: NOOP,
  readFromStorage: NOOP,
  writeToStorage: NOOP
};
function withStorageSync(configOrKey) {
  const {
    key,
    autoSync = true,
    select = state => state,
    parse = JSON.parse,
    stringify = JSON.stringify,
    storage: storageFactory = () => localStorage
  } = typeof configOrKey === 'string' ? {
    key: configOrKey
  } : configOrKey;
  return signalStoreFeature(withMethods((store, platformId = inject(PLATFORM_ID)) => {
    if (isPlatformServer(platformId)) {
      console.warn(`'withStorageSync' provides non-functional implementation due to server-side execution`);
      return StorageSyncStub;
    }
    const storage = storageFactory();
    return {
      /**
       * Removes the item stored in storage.
       */
      clearStorage() {
        storage.removeItem(key);
      },
      /**
       * Reads item from storage and patches the state.
       */
      readFromStorage() {
        const stateString = storage.getItem(key);
        if (stateString) {
          patchState$1(store, parse(stateString));
        }
      },
      /**
       * Writes selected portion to storage.
       */
      writeToStorage() {
        const slicedState = select(getState(store));
        storage.setItem(key, stringify(slicedState));
      }
    };
  }), withHooks({
    onInit(store, platformId = inject(PLATFORM_ID)) {
      if (isPlatformServer(platformId)) {
        return;
      }
      if (autoSync) {
        store.readFromStorage();
        effect(() => {
          store.writeToStorage();
        });
      }
    }
  }));
}

/** With pagination comes in two flavors the first one is local pagination or in memory pagination. For example we have 2000 items which we want
 * to display in a table and the response payload is small enough to be stored in the memory. But we can not display all 2000 items at once
 * so we need to paginate the data. The second flavor is server side pagination where the response payload is too large to be stored in the memory
 * and we need to fetch the data from the server in chunks. In the second case we 'could' also cache the data in the memory but that could lead to
 * other problems like memory leaks and stale data. So we will not cache the data in the memory in the second case.
 * This feature implements the local pagination.
 */
function withPagination(options) {
  const {
    pageKey,
    pageSizeKey,
    entitiesKey,
    selectedPageEntitiesKey,
    totalCountKey,
    pageCountKey,
    pageNavigationArrayMaxKey,
    pageNavigationArrayKey,
    hasNextPageKey,
    hasPreviousPageKey
  } = createPaginationKeys(options);
  return signalStoreFeature(withState({
    [pageKey]: 0,
    [pageSizeKey]: 10,
    [pageNavigationArrayMaxKey]: 7
  }), withComputed(store => {
    const entities = store[entitiesKey];
    const page = store[pageKey];
    const pageSize = store[pageSizeKey];
    const pageNavigationArrayMax = store[pageNavigationArrayMaxKey];
    return {
      // The derived enitites which are displayed on the current page
      [selectedPageEntitiesKey]: computed(() => {
        const pageSizeValue = pageSize();
        const pageValue = page();
        return entities().slice(pageValue * pageSizeValue, (pageValue + 1) * pageSizeValue);
      }),
      [totalCountKey]: computed(() => entities().length),
      [pageCountKey]: computed(() => {
        const totalCountValue = entities().length;
        const pageSizeValue = pageSize();
        if (totalCountValue === 0) {
          return 0;
        }
        return Math.ceil(totalCountValue / pageSizeValue);
      }),
      [pageNavigationArrayKey]: computed(() => createPageArray(page(), pageSize(), entities().length, pageNavigationArrayMax())),
      [hasNextPageKey]: computed(() => {
        return page() < pageSize();
      }),
      [hasPreviousPageKey]: computed(() => {
        return page() > 1;
      })
    };
  }));
}
function gotoPage(page, options) {
  const {
    pageKey
  } = createPaginationKeys(options);
  return {
    [pageKey]: page
  };
}
function setPageSize(pageSize, options) {
  const {
    pageSizeKey
  } = createPaginationKeys(options);
  return {
    [pageSizeKey]: pageSize
  };
}
function nextPage(options) {
  const {
    pageKey
  } = createPaginationKeys(options);
  return {
    [pageKey]: currentPage => currentPage + 1
  };
}
function previousPage(options) {
  const {
    pageKey
  } = createPaginationKeys(options);
  return {
    [pageKey]: currentPage => Math.max(currentPage - 1, 1)
  };
}
function firstPage(options) {
  const {
    pageKey
  } = createPaginationKeys(options);
  return {
    [pageKey]: 1
  };
}
function setMaxPageNavigationArrayItems(maxPageNavigationArrayItems, options) {
  const {
    pageNavigationArrayMaxKey
  } = createPaginationKeys(options);
  return {
    [pageNavigationArrayMaxKey]: maxPageNavigationArrayItems
  };
}
function createPaginationKeys(options) {
  const entitiesKey = options?.collection ? `${options.collection}Entities` : 'entities';
  const selectedPageEntitiesKey = options?.collection ? `selectedPage${capitalize(options?.collection)}Entities` : 'selectedPageEntities';
  const pageKey = options?.collection ? `${options.collection}CurrentPage` : 'currentPage';
  const pageSizeKey = options?.collection ? `${options.collection}PageSize` : 'pageSize';
  const totalCountKey = options?.collection ? `${options.collection}TotalCount` : 'totalCount';
  const pageCountKey = options?.collection ? `${options.collection}PageCount` : 'pageCount';
  const pageNavigationArrayMaxKey = options?.collection ? `${options.collection}PageNavigationArrayMax` : 'pageNavigationArrayMax';
  const pageNavigationArrayKey = options?.collection ? `${options.collection}PageNavigationArray` : 'pageNavigationArray';
  const hasNextPageKey = options?.collection ? `hasNext${capitalize(options.collection)}Page` : 'hasNextPage';
  const hasPreviousPageKey = options?.collection ? `hasPrevious${capitalize(options.collection)}Page` : 'hasPreviousPage';
  return {
    pageKey,
    pageSizeKey,
    entitiesKey,
    selectedPageEntitiesKey,
    totalCountKey,
    pageCountKey,
    pageNavigationArrayKey,
    pageNavigationArrayMaxKey,
    hasNextPageKey,
    hasPreviousPageKey
  };
}
function createPageArray(currentPage, itemsPerPage, totalItems, paginationRange) {
  // Convert paginationRange to number in case it's a string
  paginationRange = +paginationRange;
  // Calculate total number of pages
  const totalPages = Math.max(Math.ceil(totalItems / itemsPerPage), 1);
  const halfWay = Math.ceil(paginationRange / 2);
  const isStart = currentPage <= halfWay;
  const isEnd = totalPages - halfWay < currentPage;
  const isMiddle = !isStart && !isEnd;
  const ellipsesNeeded = paginationRange < totalPages;
  const pages = [];
  for (let i = 1; i <= totalPages && i <= paginationRange; i++) {
    let pageNumber = i;
    if (i === paginationRange) {
      pageNumber = totalPages;
    } else if (ellipsesNeeded) {
      if (isEnd) {
        pageNumber = totalPages - paginationRange + i;
      } else if (isMiddle) {
        pageNumber = currentPage - halfWay + i;
      }
    }
    const openingEllipsesNeeded = i === 2 && (isMiddle || isEnd);
    const closingEllipsesNeeded = i === paginationRange - 1 && (isMiddle || isStart);
    const label = ellipsesNeeded && (openingEllipsesNeeded || closingEllipsesNeeded) ? '...' : pageNumber;
    pages.push({
      label,
      value: pageNumber
    });
  }
  return pages;
}

/**
 * Adds a `resetState` method to the store, which resets the state
 * to the initial state.
 *
 * If you want to set a custom initial state, you can use {@link setResetState}.
 */
function withReset() {
  return signalStoreFeature(withProps(() => ({
    _resetState: {
      value: {}
    }
  })), withMethods(store => {
    // workaround to TS excessive property check
    const methods = {
      resetState() {
        patchState$1(store, store._resetState.value);
      },
      __setResetState__(state) {
        store._resetState.value = state;
      }
    };
    return methods;
  }), withHooks(store => ({
    onInit() {
      store._resetState.value = getState(store);
    }
  })));
}
/**
 * Sets the reset state of the store to the given state.
 *
 * Throws an error if the store is not configured with {@link withReset}.
 * @param store the instance of a SignalStore
 * @param state the state to set as the reset state
 */
function setResetState(store, state) {
  if (!('__setResetState__' in store)) {
    throw new Error('Cannot set reset state, since store is not configured with withReset()');
  }
  store.__setResetState__(state);
}

/**
 * Deep freezes a state object along its properties with primitive values
 * on the first level.
 *
 * The reason for this is that the final state is a merge of all
 * root properties of all states, i.e. `withState`,....
 *
 * Since the root object will not be part of the state (shadow clone),
 * we are not freezing it.
 */
function deepFreeze(target,
// if empty all properties will be frozen
propertyNamesToBeFrozen,
// also means that we are on the first level
isRoot = true) {
  const runPropertyNameCheck = propertyNamesToBeFrozen.length > 0;
  for (const key of Reflect.ownKeys(target)) {
    if (runPropertyNameCheck && !propertyNamesToBeFrozen.includes(key)) {
      continue;
    }
    const propValue = target[key];
    if (isRecordLike(propValue) && !Object.isFrozen(propValue)) {
      Object.freeze(propValue);
      deepFreeze(propValue, [], false);
    } else if (isRoot) {
      Object.defineProperty(target, key, {
        value: propValue,
        writable: false,
        configurable: false
      });
    }
  }
}
function isRecordLike(target) {
  return typeof target === 'object' && target !== null;
}

// necessary wrapper function to test prod mode
function isDevMode() {
  return isDevMode$1();
}
function withImmutableState(stateOrFactory, options) {
  const immutableState = typeof stateOrFactory === 'function' ? stateOrFactory() : stateOrFactory;
  const stateKeys = Reflect.ownKeys(immutableState);
  const applyFreezing = isDevMode() || options?.enableInProduction === true;
  return signalStoreFeature(withState(immutableState), withHooks(store => ({
    onInit() {
      if (!applyFreezing) {
        return;
      }
      /**
       * `immutableState` will be initially frozen. That is because
       * of potential mutations outside the SignalStore
       *
       * ```ts
       * const initialState = {id: 1};
       * signalStore(withImmutableState(initialState));
       *
       * initialState.id = 2; // must throw immutability
       * ```
       */
      Object.freeze(immutableState);
      watchState(store, state => {
        deepFreeze(state, stateKeys);
      });
    }
  })));
}

/**
 * Allows to pass properties, methods, or signals from a SignalStore
 * to a feature.
 *
 * Typically, a `signalStoreFeature` can have input constraints on
 *
 * ```typescript
 * function withSum(a: Signal<number>, b: Signal<number>) {
 *   return signalStoreFeature(
 *     withComputed(() => ({
 *       sum: computed(() => a() + b())
 *     }))
 *   );
 * }
 *
 * signalStore(
 *   withState({ a: 1, b: 2 }),
 *   withFeatureFactory((store) => withSum(store.a, store.b))
 * );
 * ```
 * @param factoryFn
 */
function withFeatureFactory(factoryFn) {
  return store => {
    const storeForFactory = {
      ...store['stateSignals'],
      ...store['props'],
      ...store['methods']
    };
    const feature = factoryFn(storeForFactory);
    return feature(store);
  };
}

/**
 * `withConditional` activates a feature based on a given condition.
 *
 * **Use Cases**
 * - Conditionally activate features based on the **store state** or other criteria.
 * - Choose between **two different implementations** of a feature.
 *
 * **Type Constraints**
 * Both features must have **exactly the same state, props, and methods**.
 * Otherwise, a type error will occur.
 *
 *
 * **Usage**
 *
 * ```typescript
 * const withUser = signalStoreFeature(
 *   withState({ id: 1, name: 'Konrad' }),
 *   withHooks(store => ({
 *     onInit() {
 *       // user loading logic
 *     }
 *   }))
 * );
 *
 * function withFakeUser() {
 *   return signalStoreFeature(
 *     withState({ id: 0, name: 'anonymous' })
 *   );
 * }
 *
 * signalStore(
 *   withMethods(() => ({
 *     useRealUser: () => true
 *   })),
 *   withConditional((store) => store.useRealUser(), withUser, withFakeUser)
 * )
 * ```
 *
 * @param condition - A function that determines which feature to activate based on the store state.
 * @param featureIfTrue - The feature to activate if the condition evaluates to `true`.
 * @param featureIfFalse - The feature to activate if the condition evaluates to `false`.
 * @returns A `SignalStoreFeature` that applies the selected feature based on the condition.
 */
function withConditional(condition, featureIfTrue, featureIfFalse) {
  return store => {
    const conditionStore = {
      ...store['stateSignals'],
      ...store['props'],
      ...store['methods']
    };
    return condition(conditionStore) ? featureIfTrue(store) : featureIfFalse(store);
  };
}
const emptyFeature = signalStoreFeature(withState({}));

/**
 * Generated bundle index. Do not edit.
 */

export { capitalize, createEffects, createPageArray, createReducer, emptyFeature, firstPage, getCallStateKeys, getDataServiceKeys, getUndoRedoKeys, gotoPage, nextPage, noPayload, patchState, payload, previousPage, renameDevtoolsName, setError, setLoaded, setLoading, setMaxPageNavigationArrayItems, setPageSize, setResetState, updateState, withCallState, withConditional, withDataService, withDevToolsStub, withDevtools, withDisabledNameIndices, withFeatureFactory, withGlitchTracking, withImmutableState, withMapper, withPagination, withRedux, withReset, withStorageSync, withUndoRedo };
