import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';

interface SidebarDisplayState {
  priceQuoteApprovalSidebarDisplayed: boolean;
  priceQuoteApprovalBulkSidebarDisplayed: boolean;
  serviceNotPossibleSidebarDisplayed: boolean;
  changeTypeSidebarDisplayed: boolean;
  deliveryNoteSidebarDisplayed: boolean;
  costEstimateSidebarDisplayed: boolean;
  changeOrderReferenceSidebarDisplayed: boolean;
  changeErpNumberSidebarDisplayed: boolean;
  selectWorkshopSidebarDisplayed: boolean;
  processDetailsSidebarDisplayed: boolean;
  countrySwitchSidebarDisplayed: boolean;
  switchViewSidebarDisplayed: boolean;
  offerPriceSidebarDisplayed: boolean;
}

const sidebarDisplayState: SidebarDisplayState = {
  priceQuoteApprovalSidebarDisplayed: false,
  priceQuoteApprovalBulkSidebarDisplayed: false,
  serviceNotPossibleSidebarDisplayed: false,
  changeTypeSidebarDisplayed: false,
  deliveryNoteSidebarDisplayed: false,
  costEstimateSidebarDisplayed: false,
  changeOrderReferenceSidebarDisplayed: false,
  changeErpNumberSidebarDisplayed: false,
  selectWorkshopSidebarDisplayed: false,
  processDetailsSidebarDisplayed: false,
  countrySwitchSidebarDisplayed: false,
  switchViewSidebarDisplayed: false,
  offerPriceSidebarDisplayed: false
};

export const SidebarDisplayStore = signalStore(
  withState<SidebarDisplayState>(sidebarDisplayState),
  withDevtools('SidebarDisplayStore'),
  withMethods((store) => ({
    showPriceQuoteApprovalSidebar: () => {
      patchState(store, { priceQuoteApprovalSidebarDisplayed: true });
    },
    hidePriceQuoteApprovalSidebar: () => {
      patchState(store, { priceQuoteApprovalSidebarDisplayed: false });
    },
    showServiceNotPossibleSidebar: () => {
      patchState(store, { serviceNotPossibleSidebarDisplayed: true });
    },
    hideServiceNotPossibleSidebar: () => {
      patchState(store, { serviceNotPossibleSidebarDisplayed: false });
    },
    showChangeTypeSidebar: () => {
      patchState(store, { changeTypeSidebarDisplayed: true });
    },
    hideChangeTypeSidebar: () => {
      patchState(store, { changeTypeSidebarDisplayed: false });
    },
    showDeliveryNoteSidebar: () => {
      patchState(store, { deliveryNoteSidebarDisplayed: true });
    },
    hideDeliveryNoteSidebar: () => {
      patchState(store, { deliveryNoteSidebarDisplayed: false });
    },
    showCostEstimateSidebar: () => {
      patchState(store, { costEstimateSidebarDisplayed: true });
    },
    hideCostEstimateSidebar: () => {
      patchState(store, { costEstimateSidebarDisplayed: false });
    },
    showChangeOrderReferenceSidebar: () => {
      patchState(store, { changeOrderReferenceSidebarDisplayed: true });
    },
    hideChangeOrderReferenceSidebar: () => {
      patchState(store, { changeOrderReferenceSidebarDisplayed: false });
    },
    showChangeErpNumberSidebar: () => {
      patchState(store, { changeErpNumberSidebarDisplayed: true });
    },
    hideChangeErpNumberSidebar: () => {
      patchState(store, { changeErpNumberSidebarDisplayed: false });
    },
    showSelectWorkshopSidebar: () => {
      patchState(store, { selectWorkshopSidebarDisplayed: true });
    },
    hideSelectWorkshopSidebar: () => {
      patchState(store, { selectWorkshopSidebarDisplayed: false });
    },
    showPriceQuoteApprovalBulkSidebar: () => {
      patchState(store, { priceQuoteApprovalBulkSidebarDisplayed: true });
    },
    hidePriceQuoteApprovalBulkSidebar: () => {
      patchState(store, { priceQuoteApprovalBulkSidebarDisplayed: false });
    },
    showProcessDetailsSidebar: () => {
      patchState(store, { processDetailsSidebarDisplayed: true });
    },
    hideProcessDetailsSidebar: () => {
      patchState(store, { processDetailsSidebarDisplayed: false });
    },
    showCountrySwitchSidebar: () => {
      patchState(store, { countrySwitchSidebarDisplayed: true });
    },
    hideCountrySwitchSidebar: () => {
      patchState(store, { countrySwitchSidebarDisplayed: false });
    },
    showSwitchViewSidebar: () => {
      patchState(store, { switchViewSidebarDisplayed: true });
    },
    hideSwitchViewSidebar: () => {
      patchState(store, { switchViewSidebarDisplayed: false });
    },
    showOfferPriceSidebar: () => {
      patchState(store, { offerPriceSidebarDisplayed: true });
    },
    hideOfferPriceSidebar: () => {
      patchState(store, { offerPriceSidebarDisplayed: false });
    }
  }))
);
